import { createStore, createHook, createContainer } from 'react-sweet-state';

import { getSpeech } from '../api/speechAPI';
import type { SpeechInput } from '../types/speechInput';
import type { ActiveAudioContent } from '../types/activeAudioContent';

export interface AudioPlaybackState {
	audioContext?: AudioContext;
	audioSrcUrl?: string;
	activeContent?: ActiveAudioContent;
	abortController?: AbortController;
	isLoading: boolean;
	isPlaying: boolean;
	error?: Error;
}

type AudioPlaybackStateContainerProps = {
	initialAudioPlaybackState?: Partial<AudioPlaybackState>;
};

const AUDIO_TYPE = 'audio/mpeg';

const createAudioSourceEndedListener = (
	audioSource: AudioBufferSourceNode,
	dispatch: any,
): AbortController => {
	const abortController = new AbortController();
	audioSource.addEventListener(
		'ended',
		() => {
			dispatch(audioPlaybackActions.initialize());
		},
		{ signal: abortController.signal },
	);

	return abortController;
};

const closeAudio = async (
	audioContext: AudioContext,
	abortController: AbortController,
	audioSrcUrl: string,
) => {
	if (audioContext) {
		if (abortController) {
			abortController.abort();
		}

		await audioContext.close();
	}

	if (audioSrcUrl) {
		window.URL.revokeObjectURL(audioSrcUrl);
	}
};

export const audioPlaybackActions = {
	initialize:
		(initialState: Partial<AudioPlaybackState> = {}) =>
		async ({ setState, getState }) => {
			const { audioContext, abortController, audioSrcUrl } = getState();

			await closeAudio(audioContext, abortController, audioSrcUrl);

			setState(
				Object.assign(
					{
						audioContext: undefined,
						audioSrcUrl: undefined,
						activeContent: undefined,
						abortController: undefined,
						isLoading: false,
						isPlaying: false,
						error: undefined,
					},
					initialState,
				),
			);
		},
	onLoadStart:
		(activeContent: ActiveAudioContent) =>
		({ setState }) => {
			setState({
				activeContent,
				isLoading: true,
			});
		},
	onLoadEnd:
		() =>
		({ setState }) => {
			setState({ isLoading: false });
		},
	onPlayContent:
		(speechInput: SpeechInput, activeContent: ActiveAudioContent) =>
		async ({ setState, dispatch }) => {
			await dispatch(audioPlaybackActions.initialize());
			dispatch(audioPlaybackActions.onLoadStart(activeContent));

			let audioContext;
			let abortController;
			let audioSrcUrl;
			try {
				const speech = await getSpeech(speechInput);
				audioSrcUrl = window.URL.createObjectURL(new Blob([speech], { type: AUDIO_TYPE }));
				// eslint-disable-next-line compat/compat
				audioContext = new window.AudioContext();
				const audioBuffer = await audioContext.decodeAudioData(speech);
				const audioSource = audioContext.createBufferSource();
				audioSource.buffer = audioBuffer;
				abortController = createAudioSourceEndedListener(audioSource, dispatch);
				audioSource.connect(audioContext.createMediaStreamDestination());

				setState({
					audioContext,
					audioSrcUrl,
					activeContent,
					abortController,
					isPlaying: true,
				});
			} catch (e) {
				await closeAudio(audioContext, abortController, audioSrcUrl);
				setState({
					error: e,
					audioContext: undefined,
					activeContent: undefined,
				});
			}

			dispatch(audioPlaybackActions.onLoadEnd());
		},
	onContentPaused:
		() =>
		({ setState }) => {
			setState({
				isPlaying: false,
			});
		},
	onContentResumed:
		() =>
		({ setState }) => {
			setState({
				isPlaying: true,
			});
		},
	onContentStopped:
		() =>
		async ({ dispatch }) => {
			await dispatch(audioPlaybackActions.initialize());
		},
	onContentError:
		(error: Error) =>
		({ setState }) => {
			setState({
				error,
			});
		},
};

export type AudioPlaybackActions = typeof audioPlaybackActions;

const Store = createStore<AudioPlaybackState, any>({
	initialState: {
		audioContext: undefined,
		audioSrcUrl: undefined,
		activeContent: undefined,
		abortController: undefined,
		isLoading: false,
		isPlaying: false,
		error: undefined,
	},
	actions: audioPlaybackActions,
	name: 'listenerState',
});

export const AudioPlaybackStateContainer = createContainer<
	AudioPlaybackState,
	AudioPlaybackActions,
	AudioPlaybackStateContainerProps
>(Store, {
	onInit:
		() =>
		async ({ dispatch }, { initialAudioPlaybackState }) => {
			void dispatch(audioPlaybackActions.initialize(initialAudioPlaybackState));
		},
});
export const useAudioPlaybackState = createHook<AudioPlaybackState, AudioPlaybackActions>(Store);
