import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import type { MutationResult, ExecutionResult } from 'react-apollo';
import { defineMessages, useIntl } from 'react-intl-next';

import { inlineCard, p } from '@atlaskit/adf-utils/builders';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { MarkdownTransformer } from '@atlaskit/editor-markdown-transformer';
import { defaultSchema as schema } from '@atlaskit/adf-schema/schema-default';

import { CreatePageMutation } from './CreatePageMutation.graphql';

type CreatePageParams = {
	pageTitle: string;
	spaceKey: string;
	parentId: string;
	content: string;
};

export type UseCreatePageReturnType = [
	createPage: (params: CreatePageParams) => Promise<ExecutionResult<any>>,
	mutationResult: MutationResult,
];

const i18n = defineMessages({
	generatedFromFooter: {
		id: 'quick-summary.create-page-modal.generated-from-footer',
		defaultMessage: 'Generated from: ',
		description:
			'footer on the newly created page that will be followed by a link to the original content that the summary was generated from',
	},
});

export const useCreatePage = (): UseCreatePageReturnType => {
	const [createPresentationMutation, mutationResults] = useMutation(CreatePageMutation);

	const { formatMessage } = useIntl();

	const createPage = useCallback(
		({ pageTitle, spaceKey, parentId, content }: CreatePageParams) => {
			const markdown = new MarkdownTransformer(schema).parse(content);
			const adf = new JSONTransformer().encode(markdown);

			const spacerParagraph = p();
			const linkParagraph = p(
				formatMessage(i18n.generatedFromFooter),
				// eslint-disable-next-line no-restricted-syntax
				inlineCard({ url: window.location.href }),
			);
			adf.content.push(spacerParagraph, linkParagraph);

			return createPresentationMutation({
				variables: {
					spaceKey,
					parentId,
					pageTitle,
					pageContent: JSON.stringify(adf),
				},
			});
		},
		[createPresentationMutation, formatMessage],
	);

	return [createPage, mutationResults];
};
