import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	attributionFooterDisclaimerLarge: {
		id: 'attribution-footer.disclaimer.large.text',
		defaultMessage: 'Content is based on your permissions and its quality may vary',
		description:
			'The text that appears on the left of the attribution footer describing content quality for large components',
	},
	attributionFooterDisclaimer: {
		id: 'attribution-footer.disclaimer.text',
		defaultMessage: 'Content quality may vary',
		description:
			'The text that appears on the left of the attribution footer describing content quality',
	},
	attributionFooterAttributionLabelLarge: {
		id: 'attribution-footer.attribution-label.large.text',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description: 'Text that appears on the right of the attribution footer for large components',
	},
	attributionFooterAttributionLabelMedium: {
		id: 'attribution-footer.attribution-label.medium.text',
		defaultMessage: 'Powered by AI',
		description: 'Text that appears on the right of the attribution footer for medium components',
	},
	atlassianIntelligenceIconAlt: {
		id: 'attribution-footer.atlassian-intelligence-icon.alt-text',
		defaultMessage: 'Atlassian Intelligence',
		description: 'alt text for the Atlassian Intelligence icon',
	},
	betaLozenge: {
		id: 'attribution-footer.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'Lozenge in the footer to indicate that this feature is in a Beta state',
	},
});
