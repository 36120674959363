import gql from 'graphql-tag';

export const CreatePageMutation = gql`
	mutation CreatePageMutation(
		$spaceKey: ID!
		$pageTitle: String!
		$parentId: ID!
		$pageContent: String!
	) {
		newPage(
			input: {
				space: { key: $spaceKey }
				page: {
					title: $pageTitle
					status: DRAFT
					parentId: $parentId
					body: { representation: ATLAS_DOC_FORMAT, value: $pageContent }
				}
			}
		) {
			page {
				id
			}
		}
	}
`;
