import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import Button from '@atlaskit/button';
import Refresh from '@atlaskit/icon/glyph/refresh';
import { token } from '@atlaskit/tokens';
import { B500, N500 } from '@atlaskit/theme/colors';
import PresenceIcon from '@atlaskit/icon/glyph/presence-active';

import type { SummaryAnalyticsPropertiesType } from './summarySharedTypes';

const i18n = defineMessages({
	refreshTooltip: {
		id: 'quick-summary.smarts-summary-header.refresh-tooltip',
		defaultMessage: 'Generate new summary',
		description: 'Tooltip when a user hovers over the refresh icon',
	},
});

const refreshContainerStyles = xcss({
	position: 'relative',
	display: 'inline-flex',
});

const refreshHighlightIconStyles = xcss({
	position: 'absolute',
	top: 'space.negative.075',
	right: 'space.negative.100',
});

type RefreshSummaryButtonProps = {
	analyticsProperties: SummaryAnalyticsPropertiesType;
	contentId: string;
	summaryId?: string;
	shouldHighlightRefresh?: boolean;
	onRefreshClicked?: () => void;
};

export const RefreshSummaryButton: FC<RefreshSummaryButtonProps> = ({
	analyticsProperties,
	contentId,
	summaryId,
	shouldHighlightRefresh = false,
	onRefreshClicked,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleRefreshClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${analyticsProperties.source}RefreshButton`,
				attributes: {
					contentId,
					summaryId,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
		onRefreshClicked?.();
	};

	return (
		<Box xcss={refreshContainerStyles}>
			<Tooltip
				position="top"
				content={intl.formatMessage(i18n.refreshTooltip)}
				testId="refresh-button-tooltip"
			>
				<Button
					appearance="subtle"
					aria-label={intl.formatMessage(i18n.refreshTooltip)}
					spacing="none"
					iconBefore={
						<Refresh label="" size="medium" primaryColor={token('color.icon.subtle', N500)} />
					}
					onClick={handleRefreshClick}
					testId="refresh-summary-button"
				/>
			</Tooltip>
			{shouldHighlightRefresh && (
				<Box xcss={refreshHighlightIconStyles}>
					<PresenceIcon
						label=""
						size="small"
						primaryColor={token('color.icon.brand', B500)}
						testId="highlight-refresh-icon"
					/>
				</Box>
			)}
		</Box>
	);
};
