import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import VolumeHighIcon from '@atlaskit/icon/core/migration/volume-high--vid-volume-full';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import type { SpeechInput } from '../types/speechInput';
import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';
import type { ActiveAudioContent } from '../types/activeAudioContent';
import type { AudioSourceType } from '../types/audioSourceType';
import { isActiveAudioContentEqual } from '../utils/activeAudioContent';

const i18n = defineMessages({
	playButtonLabel: {
		id: 'audio.play-button.label',
		defaultMessage: 'Play audio',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will initiate playing the content audio',
	},
	stopButtonLabel: {
		id: 'audio.stop-button.label',
		defaultMessage: 'Stop audio',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will stop the content audio',
	},
});

export interface ListenButtonProps {
	speechInput: SpeechInput;
	contentId: string;
	spaceKey?: string;
	contentType?: string;
	sourceType?: AudioSourceType;
}

export const ListenButton = ({
	speechInput,
	contentId,
	spaceKey,
	contentType,
	sourceType,
}: ListenButtonProps) => {
	const intl = useIntl();
	const [{ activeContent: storedActiveContent, isLoading }, { onPlayContent, onContentStopped }] =
		useAudioPlaybackState();

	const activeContent = useMemo<ActiveAudioContent>(() => {
		return {
			id: contentId,
			contentType,
			spaceKey,
			sourceType,
		};
	}, [contentId, contentType, spaceKey, sourceType]);

	const isActiveContent = useMemo(
		() => !!storedActiveContent && isActiveAudioContentEqual(storedActiveContent, activeContent),
		[storedActiveContent, activeContent],
	);

	const label = useMemo(() => {
		return isActiveContent
			? intl.formatMessage(i18n.stopButtonLabel)
			: intl.formatMessage(i18n.playButtonLabel);
	}, [intl, isActiveContent]);

	const onClick = async () => {
		isActiveContent ? await onContentStopped() : await onPlayContent(speechInput, activeContent);
	};

	return (
		<ErrorBoundary attribution={Attribution.SMARTS}>
			<IconButton
				icon={VolumeHighIcon}
				label={label}
				tooltip={{ content: label }}
				isTooltipDisabled={false}
				isLoading={isLoading}
				appearance="subtle"
				spacing="compact"
				isSelected={isActiveContent}
				onClick={onClick}
				testId="audio-listen-button"
			/>
		</ErrorBoundary>
	);
};
