import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { N200 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { FadeIn } from '@atlaskit/motion';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, Stack } from '@atlaskit/primitives';

import type { StreamError } from '@atlassian/ai-summary';

import { ErrorDisplay } from '@confluence/error-boundary';

import { HandledErrorType, useSummaryErrorHelper } from './useSummaryErrorHelper';
import { SmartsSummarizedByComponent } from './SmartsSummarizedByComponent';
import type { SummaryAnalyticsPropertiesType } from './summarySharedTypes';
import {
	ACCEPTABLE_USE_POLICY_URL,
	TROUBLE_SHOOTING_DOCS_URL,
	USAGE_LIMITS_DOCS_URL,
} from './errorMessageURLS';
import { SummaryPopupScrollableBody } from './SummaryPopupScrollableBody';

const i18nShared = defineMessages({
	generalError: {
		id: 'quick-summary.error.general-error',
		defaultMessage:
			'Atlassian Intelligence can’t provide a response right now. Read more <a>in our support documentation</a>.',
		description: 'general error message when quick summary fails',
	},
	notEnoughContent: {
		id: 'quick-summary.error.not-enough-content',
		defaultMessage:
			'There isn’t enough content here to summarize. Try again when more content has been added.',
		description: 'error message when there is not enough content to summarize',
	},
	rateLimit: {
		id: 'quick-summary.error.rate-limit',
		defaultMessage:
			'Excessive use has been detected with Atlassian Intelligence. Try again later. Read more <a>in our support documentation</a>.',
		description: 'error message when the user has hit the rate limit of quick summary',
	},
});

const i18nForPage = defineMessages({
	tooMuchContent: {
		id: 'quick-summary.error.too-much-content',
		defaultMessage:
			'Atlassian Intelligence can’t generate a summary for you right now, as there is too much content to summarize.',
		description: 'error message when there is too much content to summarize',
	},
	aupViolation: {
		id: 'quick-summary.error.aup-violation',
		defaultMessage:
			'Your page content might not comply with our Acceptable Use Policy. Please review and refer to our <a>Acceptable Use Policy</a> if needed.',
		description: "error message when the content fails Atlassian's acceptable use policy",
	},
});

const i18nForComments = defineMessages({
	tooMuchContent: {
		id: 'quick-summary.error.too-much-content-comments',
		defaultMessage:
			'There’s too much content for Atlassian Intelligence to summarize. Try again on a page with fewer comments.',
		description: 'error message when there is too much content to summarize',
	},
	aupViolation: {
		id: 'quick-summary.error.aup-violation-comments',
		defaultMessage:
			'We can’t provide a summary of the comments on this page at this time, as some comments may not comply with Atlassian’s <a>Acceptable Use Policy</a>.',
		description: "error message when the content fails Atlassian's acceptable use policy",
	},
});

const i18nForPageCatchup = defineMessages({
	tooMuchContent: {
		id: 'quick-summary.error.too-much-content-page-catchup',
		defaultMessage:
			'There are too many changes for Atlassian Intelligence (AI) to summarize. Try again on a page with fewer changes.',
		description: 'error message when there is too much content to summarize',
	},
	aupViolation: {
		id: 'quick-summary.error.aup-violation-page-catchup',
		defaultMessage:
			'Atlassian Intelligence (AI) can’t provide a summary of the changes on this page, as some changes may not comply with our <a>Acceptable Use Policy</a>.',
		description: "error message when the content fails Atlassian's acceptable use policy",
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuickSummaryErrorBody = styled(SummaryPopupScrollableBody)({
	color: token('color.text.subtlest', N200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
});

export type QuickSummaryComponentProps = {
	analyticsProperties: SummaryAnalyticsPropertiesType;
	contentId: string;
	errorFromApolloOrStream?: ApolloError | StreamError;
	experienceName: string;
	summarizedByInHeader?: boolean;
	shouldShowBetaFlag?: boolean;
};

export const SmartsSummaryErrorComponent: FC<QuickSummaryComponentProps> = ({
	analyticsProperties,
	errorFromApolloOrStream,
	contentId,
	experienceName,
	summarizedByInHeader = false,
	shouldShowBetaFlag = false,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isExpectedError, stopExperienceTracking, errorType, error } = useSummaryErrorHelper(
		experienceName,
		errorFromApolloOrStream,
	);

	const errorScreenEventName =
		analyticsProperties.source === 'quickSummary'
			? 'quickSummaryDialogError'
			: `${analyticsProperties.source}Error`;

	const sourceBasedErrorMessagesSet = () => {
		switch (analyticsProperties.source) {
			case 'commentsSummary':
				return i18nForComments;
			case 'pageCatchup':
				return i18nForPageCatchup;
			default:
				return i18nForPage;
		}
	};

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: errorScreenEventName,
				attributes: {
					contentId,
					errorType: errorType || 'generalError',
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
	}, [contentId, createAnalyticsEvent, errorScreenEventName, errorType, analyticsProperties]);

	const onDocsLinkClicked = (linkName: string) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: `atlassianIntelligence${linkName}DocsLink`,
				attributes: {
					contentId,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
	};

	let errorMessage: ReactNode;

	switch (errorType) {
		case HandledErrorType.TooMuchContent:
			errorMessage = intl.formatMessage(sourceBasedErrorMessagesSet().tooMuchContent);
			break;
		case HandledErrorType.NotEnoughContent:
			errorMessage = intl.formatMessage(i18nShared.notEnoughContent);
			break;
		case HandledErrorType.AUPViolation:
			errorMessage = intl.formatMessage(sourceBasedErrorMessagesSet().aupViolation, {
				a: (chunks: React.ReactNode[]) => (
					<a
						href={ACCEPTABLE_USE_POLICY_URL}
						target="_blank"
						onClick={() => onDocsLinkClicked('AupViolation')}
					>
						{chunks}
					</a>
				),
			});
			break;
		case HandledErrorType.RateLimit:
			errorMessage = intl.formatMessage(i18nShared.rateLimit, {
				a: (chunks: React.ReactNode[]) => (
					<a
						href={USAGE_LIMITS_DOCS_URL}
						target="_blank"
						onClick={() => onDocsLinkClicked('RateLimit')}
					>
						{chunks}
					</a>
				),
			});
			break;
		default:
			errorMessage = intl.formatMessage(i18nShared.generalError, {
				a: (chunks: React.ReactNode[]) => (
					<a
						href={TROUBLE_SHOOTING_DOCS_URL}
						target="_blank"
						onClick={() => onDocsLinkClicked('Troubleshooting')}
					>
						{chunks}
					</a>
				),
			});
	}

	stopExperienceTracking();

	const summarizedByComponent = (
		<SmartsSummarizedByComponent
			analyticsProperties={analyticsProperties}
			contentId={contentId}
			error
			shouldShowBetaFlag={shouldShowBetaFlag}
		/>
	);

	return (
		<FadeIn entranceDirection="top">
			{(props) => (
				<Box {...props} padding="space.250">
					<Stack space="space.250" testId="smmarts-summary-error">
						{summarizedByInHeader && summarizedByComponent}
						<QuickSummaryErrorBody>
							<p>{errorMessage}</p>
						</QuickSummaryErrorBody>
						{!summarizedByInHeader && summarizedByComponent}
					</Stack>
					{!isExpectedError && <ErrorDisplay error={error} />}
				</Box>
			)}
		</FadeIn>
	);
};
