import React, { createContext, useContext, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

type CreatePageModalContextType = {
	contentId?: string | null;
	spaceKey?: string | null;
	contentTitle?: string | null;
};

const CreatePageModalContext = createContext<CreatePageModalContextType | undefined>(undefined);

const CreatePageModalContextProvider: FC<CreatePageModalContextType & { children: ReactNode }> = ({
	children,
	contentId,
	spaceKey,
	contentTitle,
}) => {
	const contextValue = useMemo<CreatePageModalContextType>(
		() => ({
			contentId,
			spaceKey,
			contentTitle,
		}),
		[contentId, spaceKey, contentTitle],
	);

	return (
		<CreatePageModalContext.Provider value={contextValue}>
			{children}
		</CreatePageModalContext.Provider>
	);
};

const useCreatePageModalContext = () => {
	const context = useContext(CreatePageModalContext);
	if (!context) {
		throw new Error(
			'useCreatePageModalContext must be used within a CreatePageModalContextProvider',
		);
	}
	return context;
};

export { CreatePageModalContextProvider, useCreatePageModalContext };
