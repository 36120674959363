import { cfetch } from '@confluence/network';

import type { SpeechInput } from '../types/speechInput';
import type { SpeechInputType } from '../types/speechInputType';

export const SPEECH_API_ROOT = '/gateway/api/v1/speech';

export const getSpeech = async (
	input: SpeechInput,
	inputType: SpeechInputType = 'text',
): Promise<ArrayBuffer> => {
	const response = await cfetch(SPEECH_API_ROOT, {
		method: 'POST',
		body: JSON.stringify({
			input,
			inputType,
		}),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	return response.arrayBuffer();
};
