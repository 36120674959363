import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import React, { useLayoutEffect, useRef, useState } from 'react';

import CopyIcon from '@atlaskit/icon/glyph/copy';
import { token } from '@atlaskit/tokens';
import { N500 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import Button from '@atlaskit/button';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import type { SummaryAnalyticsPropertiesType } from './summarySharedTypes';

const i18n = defineMessages({
	copyTooltip: {
		id: 'quick-summary.quick-summary-header.copy-tooltip',
		defaultMessage: 'Copy summary',
		description: 'Tooltip when user hovers over copy icon',
	},
	copiedTooltip: {
		id: 'quick-summary.quick-summary-header.copied-tooltip',
		defaultMessage: 'Copied!',
		description: 'Tooltip for copy icon after user has clicked to copy the text',
	},
});

type CopySummaryButtonProps = {
	analyticsProperties: SummaryAnalyticsPropertiesType;
	contentId: string;
	summaryText: string;
	summaryId?: string;
};

export const CopySummaryButton: FC<CopySummaryButtonProps> = ({
	analyticsProperties,
	contentId,
	summaryText,
	summaryId,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { trackAIResultAction } = useAIEventsInstrumentation();

	const [copyTooltipValue, setCopyTooltipValue] = useState<string>(
		intl.formatMessage(i18n.copyTooltip),
	);

	const updateTooltip = useRef<() => void>();

	useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [copyTooltipValue]);

	// TODO: Copying summaries isn't great right now. Bullet points and emojis are omitted.
	// Will fix as part of https://hello.jira.atlassian.cloud/browse/CCSMART-1604
	const copySummary = async () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${analyticsProperties.source}CopySummaryButton`,
				attributes: {
					contentId,
					summaryId,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
		await navigator.clipboard.writeText(summaryText);
		setCopyTooltipValue(intl.formatMessage(i18n.copiedTooltip));
		setTimeout(() => setCopyTooltipValue(intl.formatMessage(i18n.copyTooltip)), 1000);
		trackAIResultAction('copyButtonClicked');
	};

	return (
		<Tooltip
			position="top"
			content={({ update }) => {
				updateTooltip.current = update;
				return <span role="status">{copyTooltipValue}</span>;
			}}
		>
			<Button
				appearance="subtle"
				aria-label={intl.formatMessage(i18n.copyTooltip)}
				spacing="none"
				iconBefore={
					<CopyIcon label="" size="medium" primaryColor={token('color.icon.subtle', N500)} />
				}
				onClick={copySummary}
				testId="copy-summary-button"
			/>
		</Tooltip>
	);
};
