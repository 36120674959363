import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

export const MAX_SUMMARY_HEIGHT = 358;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryPopupScrollableBody = styled.div({
	fontSize: '16px',
	lineHeight: '24px',
	maxHeight: `${MAX_SUMMARY_HEIGHT}px`,
	overflowY: 'auto',
	overflowWrap: 'break-word',
	whiteSpace: 'normal',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar': {
		width: token('space.100', '8px'),
		background: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar-thumb': {
		borderRadius: token('space.100', '8px'),
		backgroundColor: token('color.background.neutral'),
		'&:hover': {
			backgroundColor: token('color.background.neutral.hovered'),
		},
	},
});
