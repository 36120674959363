import type { FC } from 'react';
import React, { useState, useCallback, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/new';
import { useFlags } from '@atlaskit/flag';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import {
	ExperienceTrackerContext,
	QUICK_SUMMARY_TO_PAGE_EXPERIENCE,
} from '@confluence/experience-tracker';
import { EDIT_PAGE_V2 } from '@confluence/named-routes';
import { expValEquals } from '@confluence/feature-experiments';

import { CreatePageModal } from './CreatePageModal';
import { useCreatePageModalContext } from './CreatePageModalContextProvider';
import { useCreatePage } from './useCreatePage';

const i18n = defineMessages({
	createPageButton: {
		id: 'quick-summary.create-page-button',
		defaultMessage: 'Create page',
		description: 'Button in quick summary footer to launch modal for converting summary to page',
	},
	errorFlagTitle: {
		id: 'quick-summary.create-page-modal.error-flag-title',
		defaultMessage: 'We couldn’t create the page',
		description: 'title for the error flag to show if there is an issue with creating the page',
	},
	errorFlagBody: {
		id: 'quick-summary.create-page-modal.error-flag-body',
		defaultMessage: 'There was an error creating the summary page. Reload and try again.',
		description: 'body text for the error flag to show if there is an issue with creating the page',
	},
	titleWithSummaryHyphen: {
		id: 'quick-summary.create-page-modal.title-suffix',
		defaultMessage: 'Summary of {contentTitle}',
		description:
			'prefix added to the original whiteboard title to create the title of the new page',
	},
});

export type CreatePageButtonProps = {
	summaryText: string;
};

const CreatePageButtonComponent: FC<CreatePageButtonProps> = ({ summaryText }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { trackAIResultAction } = useAIEventsInstrumentation();

	const openModal = useCallback(() => {
		trackAIResultAction('createPageButtonClicked');
		setIsModalOpen(true);
	}, [trackAIResultAction]);

	const closeModal = useCallback(() => {
		trackAIResultAction('createPageModalClosed');
		setIsModalOpen(false);
	}, [trackAIResultAction]);

	const { formatMessage } = useIntl();

	const { contentId, spaceKey, contentTitle } = useCreatePageModalContext();

	const experienceTracker = useContext(ExperienceTrackerContext);

	const [createPage, { loading, error }] = useCreatePage();

	const { showFlag } = useFlags();

	const { push } = useRouteActions();

	if (!spaceKey || !contentId || !contentTitle) {
		return null;
	}

	const handleCreatePage = async (parentPageId = contentId, destinationSpaceKey = spaceKey) => {
		trackAIResultAction('createPageModalConfirmButtonClicked');
		experienceTracker.start({
			name: QUICK_SUMMARY_TO_PAGE_EXPERIENCE,
		});
		try {
			const pageTitle = formatMessage(i18n.titleWithSummaryHyphen, { contentTitle });
			const result = await createPage({
				parentId: parentPageId,
				spaceKey: destinationSpaceKey,
				pageTitle,
				content: summaryText,
			});

			const draftUrl = EDIT_PAGE_V2.toUrl({
				contentType: 'pages',
				spaceKey: destinationSpaceKey,
				contentId: result.data.newPage.page.id,
			});

			experienceTracker.succeed({
				name: QUICK_SUMMARY_TO_PAGE_EXPERIENCE,
			});
			push(draftUrl);
		} catch (err) {
			experienceTracker.fail({
				name: QUICK_SUMMARY_TO_PAGE_EXPERIENCE,
				error: err,
			});

			showFlag({
				title: formatMessage(i18n.errorFlagTitle),
				description: formatMessage(i18n.errorFlagBody),
				icon: <ErrorIcon color={token('color.icon.danger')} label="" />,
				isAutoDismiss: true,
			});
		}
	};

	const handleCreatePageButtonClick = () => {
		if (expValEquals('cc_quick_summary_create_page_direct', 'cohort', 'test')) {
			void handleCreatePage();
		} else {
			openModal();
		}
	};

	return (
		<>
			<Button appearance="primary" onClick={handleCreatePageButtonClick} isLoading={loading}>
				{formatMessage(i18n.createPageButton)}
			</Button>
			{isModalOpen && (
				<CreatePageModal
					onClose={closeModal}
					spaceKey={spaceKey}
					contentId={contentId}
					handleCreatePage={handleCreatePage}
					loading={loading}
				/>
			)}
			{error && <ErrorDisplay error={error} />}
		</>
	);
};

export const CreatePageButton = (props: CreatePageButtonProps) => {
	return (
		<ErrorBoundary attribution={Attribution.SMARTS}>
			<CreatePageButtonComponent {...props} />
		</ErrorBoundary>
	);
};
