import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Inline, Stack, Text, Box, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import Link from '@atlaskit/link';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import type { PageType } from '@confluence/page-selector';
import { PageSelector } from '@confluence/page-selector';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import type { SelectedSpaceOnChangeProps } from '@confluence/space-selector';
import { SpaceSelector } from '@confluence/space-selector';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import { useClassificationChangeState } from '@confluence/data-classification';
import { useIsFolderEnabled } from '@confluence/folder-utils/entry-points/useIsFolderEnabled';

export const MOVE_CONTENT_SUPPORT_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/move-copy-and-hide-pages/';

const i18n = defineMessages({
	modalTitle: {
		id: 'quick-summary.create-page-modal.title',
		defaultMessage: 'Select location',
		description: 'title for the create page modal',
	},
	modalBody: {
		id: 'quick-summary.create-page-modal.body',
		defaultMessage:
			'Select the space and parent item for your generated page. <Link>Learn about moving content</Link>',
		description: 'title for the create page modal',
	},
	cancelButton: {
		id: 'quick-summary.create-page-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'button to close the create page modal',
	},
	confirmButton: {
		id: 'quick-summary.create-page-modal.confirm-button',
		defaultMessage: 'Confirm',
		description: 'button to submit the create page modal and create a new page',
	},
});

const bodyTextWrapper = xcss({
	marginBottom: 'space.negative.250',
});

export type CreatePageModalProps = {
	onClose: () => void;
	spaceKey: string;
	contentId: string;
	handleCreatePage: (parentId?: string, spaceKey?: string) => void;
	loading: boolean;
};

export const CreatePageModal: FC<CreatePageModalProps> = ({
	onClose,
	spaceKey,
	contentId,
	handleCreatePage,
	loading,
}) => {
	const { formatMessage } = useIntl();

	const { trackAIResultAction } = useAIEventsInstrumentation();

	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();
	const isWhiteboardInContentTreeEnabled = isWhiteboardFeatureEnabled('whiteboardsEnabled');
	const isDatabaseCreationEnabled = useBooleanFeatureFlag('confluence.frontend.databases.enabled');
	const { isFolderEnabled } = useIsFolderEnabled();

	const [parentPageId, setParentPageId] = useState<string>(contentId);
	const [destinationSpaceKey, setDestinationSpaceKey] = useState<string>(spaceKey);

	const onPageSelectorChange = (selectedPage: PageType) => {
		const newPageId = selectedPage.id;
		if (newPageId !== parentPageId) {
			trackAIResultAction('createPageModalParentChanged');
		}
		setParentPageId(newPageId);
	};

	const onSpaceSelectorChange = (selectedSpace: SelectedSpaceOnChangeProps) => {
		trackAIResultAction('createPageModalSpaceChanged');
		const { spaceKey: selectedSpaceKey, homepageId: selectedSpaceHomepageId } = selectedSpace;
		setDestinationSpaceKey(selectedSpaceKey);
		setParentPageId(selectedSpaceHomepageId);
	};

	const { selectedClassification, classificationError } = useClassificationChangeState(
		contentId,
		destinationSpaceKey,
		spaceKey,
	);

	const requiredSpaceOperations = [
		{
			targetType: 'page',
			operation: 'create',
		},
	];

	return (
		<ModalTransition>
			<Modal onClose={onClose}>
				<ModalHeader>
					<ModalTitle>{formatMessage(i18n.modalTitle)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Stack space="space.200">
						<Box xcss={bodyTextWrapper}>
							<Text>
								{formatMessage(i18n.modalBody, {
									Link: (chunks: ReactNode[]) => (
										<Link target="_blank" href={MOVE_CONTENT_SUPPORT_LINK}>
											{chunks}
										</Link>
									),
								})}
							</Text>
						</Box>
						<SpaceSelector
							onChange={onSpaceSelectorChange}
							operationsUsedToDetermineRestrictedStatus={requiredSpaceOperations}
							showRestrictedSpaces={false}
							filterDefaultFromResults={false}
							spaceKey={destinationSpaceKey}
							spaceClassification={selectedClassification}
							hasClassificationError={!!classificationError}
							isDisabled={loading}
							filterStarredFromRecent
							labelTextTransform="none"
							useMenuPortalTarget
							selectStyleOverrides={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
							}}
						/>
						<PageSelector
							key={`${destinationSpaceKey}${parentPageId}`}
							spaceKey={destinationSpaceKey}
							defaultPageId={parentPageId}
							isDisabled={loading}
							onChange={onPageSelectorChange}
							isWhiteboardInContentTreeEnabled={isWhiteboardInContentTreeEnabled}
							isDatabaseCreationEnabled={isDatabaseCreationEnabled}
							isFolderEnabled={isFolderEnabled}
							useMenuPortalTarget
							selectStyleOverrides={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
							}}
						/>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Inline space="space.100">
						<Button appearance="subtle" onClick={onClose}>
							{formatMessage(i18n.cancelButton)}
						</Button>
						<Button
							appearance="primary"
							isLoading={loading}
							onClick={() => handleCreatePage(parentPageId, destinationSpaceKey)}
						>
							{formatMessage(i18n.confirmButton)}
						</Button>
					</Inline>
				</ModalFooter>
			</Modal>
		</ModalTransition>
	);
};
