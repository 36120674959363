import React from 'react';
import { styled } from '@compiled/react';

import { LoadableLazy } from '@confluence/loadable';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingSpaceSelector = styled.div({
	minHeight: '40px',
});

export const SpaceSelector = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceSelector" */ './SpaceSelector'))
			.SpaceSelector,
	loading: () => <LoadingSpaceSelector />,
});

export type {
	SpaceSelectorProps,
	SelectedSpaceOnChangeProps,
	OnLoadDefaultSpaceFcn,
} from './SpaceSelector';
