import type { FC, MouseEvent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import React from 'react';

import { token } from '@atlaskit/tokens';
import { N0, N300 } from '@atlaskit/theme/colors';
import { Flex, Inline, Stack } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import Button from '@atlaskit/button';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Lozenge from '@atlaskit/lozenge';

import { ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK } from '../constants';
import { AtlassianIntelligenceLogo } from '../assets/AtlassianIntelligenceLogo';
import { InfoIcon } from '../assets/InfoIcon';

import type { SummaryAnalyticsPropertiesType } from './summarySharedTypes';

const i18n = defineMessages({
	footerTitle: {
		id: 'quick-summary.quick-summary-footer.footer-title',
		defaultMessage: 'Summarized by Atlassian Intelligence',
		description: 'Title for the a summary feedback section',
	},
	whatElseCanAIDoLink: {
		id: 'quick-summary.quick-summary-footer.what-can-ai-do-link',
		defaultMessage: 'What else can Atlassian Intelligence help me with?',
		description: 'Link to Atlassian Intelligence user documentation',
	},
	footerTitleError: {
		id: 'quick-summary.quick-summary-footer.footer-title-error',
		defaultMessage: 'Atlassian Intelligence',
		description: 'Title for a summary feedback section in the event of an error',
	},
	qualityDescriptor: {
		id: 'quick-summary.quick-summary-footer.quality-descriptor',
		defaultMessage:
			'The quality, accuracy, and reliability of information generated by Atlassian Intelligence may vary.',
		description: 'Notification that summary quality may be variable',
	},
	learnMoreLink: {
		id: 'quick-summary.quick-summary-footer.learn-more-link',
		defaultMessage: 'Learn more',
		description: 'Link to Atlassian Intelligence user documentation',
	},
	logoAltText: {
		id: 'quick-summary.quick-summary-footer.logo-alt-text',
		defaultMessage: 'Atlassian Intelligence Logo',
		description: 'Alt text for Atlassian Intelligence logo',
	},
	moreInfo: {
		id: 'quick-summary.quick-summary-footer.more-info',
		defaultMessage: 'more info',
		description: 'Alt text for the info icon in the footer of a summary card',
	},
	betaLozenge: {
		id: 'quick-summary.summary-footer.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'Lozenge in the footer to indicate that this feature is in a Beta state',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.span({
	fontWeight: '600',
	fontSize: '12px',
	color: token('color.text.subtle', N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LearnMoreWrapper = styled.span({
	color: token('color.text.inverse', N0),
	textDecoration: 'underline',
	fontWeight: '400',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuestionLink = styled.span({
	color: token('color.text.subtlest', '#626f86'),
	textDecoration: 'underline',
	fontSize: '12px',
});

type SmartsSummarizedByComponentProps = {
	analyticsProperties: SummaryAnalyticsPropertiesType;
	contentId: string;
	summaryId?: string;
	summaryLocale?: string;
	error?: boolean;
	shouldShowLearnMoreLink?: boolean;
	shouldShowBetaFlag?: boolean;
};

export const SmartsSummarizedByComponent: FC<SmartsSummarizedByComponentProps> = ({
	analyticsProperties,
	contentId,
	summaryId,
	error,
	shouldShowLearnMoreLink = true,
	shouldShowBetaFlag = false,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onLearnMoreClicked = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${analyticsProperties.source}LearnWhyLink`,
				attributes: {
					contentId,
				},
			},
		}).fire();
	};

	const onAIDocsLinkClicked = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'atlassianIntelligenceProductInfoLink',
				attributes: {
					contentId,
					summaryId,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
	};

	const moreInfoContent = (
		<p>
			{intl.formatMessage(i18n.qualityDescriptor)}
			&nbsp;
			<Button
				appearance="subtle-link"
				spacing="none"
				onClick={onLearnMoreClicked}
				href={ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK}
				target="_blank"
			>
				<LearnMoreWrapper>{intl.formatMessage(i18n.learnMoreLink)}</LearnMoreWrapper>
			</Button>
		</p>
	);

	return (
		<Inline space="space.050" alignBlock="start">
			<AtlassianIntelligenceLogo alt={intl.formatMessage(i18n.logoAltText)} />
			<Stack>
				<Inline space="space.050" alignBlock="center">
					<Title>
						{error
							? intl.formatMessage(i18n.footerTitleError)
							: intl.formatMessage(i18n.footerTitle)}
					</Title>
					{shouldShowBetaFlag && (
						<Lozenge appearance="new">
							<FormattedMessage {...i18n.betaLozenge} />
						</Lozenge>
					)}
					<Tooltip content={moreInfoContent} position="top">
						<Flex>
							<InfoIcon label={intl.formatMessage(i18n.moreInfo)} />
						</Flex>
					</Tooltip>
				</Inline>
				{!error && shouldShowLearnMoreLink && (
					<Button
						appearance="subtle-link"
						spacing="none"
						onClick={onAIDocsLinkClicked}
						href={ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK}
						target="_blank"
					>
						<QuestionLink>{intl.formatMessage(i18n.whatElseCanAIDoLink)}</QuestionLink>
					</Button>
				)}
			</Stack>
		</Inline>
	);
};
