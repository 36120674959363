import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const InfoIconSvg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM8 12.6667C10.5773 12.6667 12.6667 10.5773 12.6667 8C12.6667 5.42267 10.5773 3.33333 8 3.33333C5.42267 3.33333 3.33333 5.42267 3.33333 8C3.33333 10.5773 5.42267 12.6667 8 12.6667ZM8 11.3333C7.65811 11.3333 7.37633 11.076 7.33782 10.7444L7.33333 10.6667V8C7.33333 7.63181 7.63181 7.33333 8 7.33333C8.34189 7.33333 8.62367 7.59069 8.66218 7.92225L8.66667 8V10.6667C8.66667 11.0349 8.36819 11.3333 8 11.3333Z"
			fill="currentColor"
		/>
		<path
			d="M8 4.66667C7.53976 4.66667 7.16667 5.03976 7.16667 5.5C7.16667 5.96024 7.53976 6.33333 8 6.33333C8.46024 6.33333 8.83333 5.96024 8.83333 5.5C8.83333 5.03976 8.46024 4.66667 8 4.66667Z"
			fill="currentColor"
		/>
	</svg>
);

export const InfoIcon = ({ label }: GlyphProps) => (
	<Icon
		label={label}
		glyph={InfoIconSvg}
		size="small"
		primaryColor={token('color.icon.subtle', N500)}
	/>
);
