/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';

export const AtlassianIntelligenceLogo = ({ alt }: { alt: string }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<title>{alt}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4473 7.99998C11.4473 7.63179 11.7457 7.33331 12.1139 7.33331H13.3339C13.7021 7.33331 14.0006 7.63179 14.0006 7.99998C14.0006 8.36817 13.7021 8.66665 13.3339 8.66665H12.1139C11.7457 8.66665 11.4473 8.36817 11.4473 7.99998Z"
			fill="url(#paint0_linear_2378_7139)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.00065 2C8.36884 2 8.66732 2.29848 8.66732 2.66667V3.88667C8.66732 4.25486 8.36884 4.55333 8.00065 4.55333C7.63246 4.55333 7.33398 4.25486 7.33398 3.88667V2.66667C7.33398 2.29848 7.63246 2 8.00065 2Z"
			fill="url(#paint1_linear_2378_7139)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 7.99998C2 7.63179 2.29848 7.33331 2.66667 7.33331H3.88667C4.25486 7.33331 4.55333 7.63179 4.55333 7.99998C4.55333 8.36817 4.25486 8.66665 3.88667 8.66665H2.66667C2.29848 8.66665 2 8.36817 2 7.99998Z"
			fill="url(#paint2_linear_2378_7139)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.00065 11.4467C8.36884 11.4467 8.66732 11.7451 8.66732 12.1133V13.3333C8.66732 13.7015 8.36884 14 8.00065 14C7.63246 14 7.33398 13.7015 7.33398 13.3333V12.1133C7.33398 11.7451 7.63246 11.4467 8.00065 11.4467Z"
			fill="url(#paint3_linear_2378_7139)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.52925 9.52858C9.7896 9.26823 10.2117 9.26823 10.4721 9.52858L13.1387 12.1952C13.3991 12.4556 13.3991 12.8777 13.1387 13.1381C12.8784 13.3984 12.4563 13.3984 12.1959 13.1381L9.52925 10.4714C9.2689 10.211 9.2689 9.78892 9.52925 9.52858Z"
			fill="#2684FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.1387 2.86195C13.3991 3.1223 13.3991 3.54441 13.1387 3.80476L10.4721 6.47142C10.2117 6.73177 9.7896 6.73177 9.52925 6.47142C9.2689 6.21108 9.2689 5.78897 9.52925 5.52862L12.1959 2.86195C12.4563 2.6016 12.8784 2.6016 13.1387 2.86195Z"
			fill="#2684FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.86128 2.86195C3.12163 2.6016 3.54374 2.6016 3.80409 2.86195L6.47075 5.52862C6.7311 5.78897 6.7311 6.21108 6.47075 6.47142C6.2104 6.73177 5.78829 6.73177 5.52794 6.47142L2.86128 3.80476C2.60093 3.54441 2.60093 3.1223 2.86128 2.86195Z"
			fill="#2684FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.47075 9.52858C6.7311 9.78892 6.7311 10.211 6.47075 10.4714L3.80409 13.1381C3.54374 13.3984 3.12163 13.3984 2.86128 13.1381C2.60093 12.8777 2.60093 12.4556 2.86128 12.1952L5.52794 9.52858C5.78829 9.26823 6.2104 9.26823 6.47075 9.52858Z"
			fill="#2684FF"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_2378_7139"
				x1="14.0006"
				y1="7.99998"
				x2="11.6672"
				y2="7.99998"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.270833" stopColor="#2684FF" />
				<stop offset="1" stopColor="#0052CC" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_2378_7139"
				x1="8.33398"
				y1="2"
				x2="8.33398"
				y2="4.33333"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.1875" stopColor="#2684FF" />
				<stop offset="1" stopColor="#0052CC" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_2378_7139"
				x1="4.5533"
				y1="7.99998"
				x2="2.21997"
				y2="7.99998"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" />
				<stop offset="0.791667" stopColor="#2684FF" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_2378_7139"
				x1="8.33398"
				y1="11.3333"
				x2="8.33398"
				y2="14"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" />
				<stop offset="0.802083" stopColor="#2684FF" />
			</linearGradient>
		</defs>
	</svg>
);
