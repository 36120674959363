import type { ActiveAudioContent } from '../types/activeAudioContent';

export const isActiveAudioContentEqual = (
	audioContent1: ActiveAudioContent,
	audioContent2: ActiveAudioContent,
): boolean => {
	return (
		audioContent1.id === audioContent2.id &&
		audioContent1.spaceKey === audioContent2.spaceKey &&
		audioContent1.contentType === audioContent2.contentType &&
		audioContent1.sourceType === audioContent2.sourceType
	);
};

export const activeAudioContentHasSmartLinkData = (
	activeAudioContent: ActiveAudioContent,
): boolean => {
	return (
		!!activeAudioContent.id && !!activeAudioContent.spaceKey && !!activeAudioContent.contentType
	);
};
