import React, { type FC, useState } from 'react';

import { useIntl } from 'react-intl-next';

import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import EditorPanelIcon from '@atlaskit/icon/utility/migration/information--editor-panel';
import { AtlassianIntelligenceDarkIcon } from '@atlaskit/legacy-custom-icons';
import Lozenge from '@atlaskit/lozenge';
import { Anchor, Inline, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';

import { LARGE_BREAKPOINT, LINK_HREF, MEDIUM_BREAKPOINT } from './constants';
import { i18n } from './messages';

const footerContainerStyles = xcss({
	position: 'relative',
});

const footerDisclaimerStyles = xcss({
	paddingBlock: 'space.050',
});

const linkStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body.small'),
	textDecoration: 'none',

	':hover': {
		color: 'color.text.selected',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
});

type AIAttributionFooterProps = {
	onLinkClick?: () => void;
	showBetaLozenge?: boolean;
};

export const AIAttributionFooter: FC<AIAttributionFooterProps> = ({
	onLinkClick,
	showBetaLozenge,
}) => {
	const { formatMessage } = useIntl();
	const [footerWidth, setFooterWidth] = useState(0);

	const betaLozengeText = formatMessage(i18n.betaLozenge);

	const attributionMessage = () => {
		if (footerWidth >= LARGE_BREAKPOINT) {
			return formatMessage(i18n.attributionFooterAttributionLabelLarge);
		} else if (footerWidth >= MEDIUM_BREAKPOINT && footerWidth < LARGE_BREAKPOINT) {
			return formatMessage(i18n.attributionFooterAttributionLabelMedium);
		} else {
			return null;
		}
	};

	return (
		<Inline alignBlock="center" spread="space-between" xcss={footerContainerStyles}>
			<WidthObserver setWidth={setFooterWidth} />
			<Inline alignBlock="center" xcss={footerDisclaimerStyles} space="space.050">
				{showBetaLozenge && <Lozenge>{betaLozengeText}</Lozenge>}
				<EditorPanelIcon
					label=""
					LEGACY_margin={token('space.negative.050')}
					color={token('color.icon.subtle', '#626F86')}
				/>
				<Anchor xcss={linkStyles} href={LINK_HREF} target="_blank" onClick={onLinkClick}>
					{footerWidth >= LARGE_BREAKPOINT
						? formatMessage(i18n.attributionFooterDisclaimerLarge)
						: formatMessage(i18n.attributionFooterDisclaimer)}
				</Anchor>
			</Inline>
			<Inline space="space.075" alignBlock="center">
				<Text size="small" color="color.text.subtlest">
					{attributionMessage()}
				</Text>
				<AtlassianIntelligenceIcon
					color={token('color.icon')}
					label={formatMessage(i18n.atlassianIntelligenceIconAlt)}
					LEGACY_fallbackIcon={AtlassianIntelligenceDarkIcon}
				/>
			</Inline>
		</Inline>
	);
};
